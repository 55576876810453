<template>

  <div class="emt"> 
    
    <i class="icon-not-eye"></i>
     
    <div class="txt">
      {{ props.text ?? $t('universal_no_found') }}
    </div>

  </div>
  
</template>


<script setup lang="ts">

interface PageProps {
  text?: string | null
}

const props = withDefaults(defineProps<PageProps>(), {
  text: ''
})

</script>
 


<style scoped>
  .emt{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    min-height: 300px;
    font-weight: bold;
  }

  i{
    font-size: 2rem;
    margin-bottom: 1rem;
  }

</style>